import React, {
  FC,
  useEffect,
  useState,
  FormEvent,
  createRef,
  KeyboardEvent,
} from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Flex, IconButton, Text } from '@ads/front-ds'
import { ExternalTextLink } from '@ads/front-core/atoms'
import { lng, Trans } from '@ads/front-core/locales'
import { getScrollbarStyles } from '@ads/front-core/utils'
import {
  CloudflareTurnstile,
  TurnstileVariant,
} from '@ads/front-core/components/CloudflareTurnstile'
import { getAIAgentLimitExceeded } from 'store/selectors/ai/getAIAgentLimitExceeded'
import { useData } from './useData'

const VALUE_LENGTH_SHOW_COUNTER = 1600
const MAX_VALUE_LENGTH = 2000

export const AIPopupInput: FC = () => {
  const inputRef = createRef<HTMLDivElement>()
  const [message, setMessage] = useState<string>('')
  const [isFocused, setIsFocused] = useState(false)
  const [isPlaceholder, setIsPlaceholder] = useState(false)

  const isLimitExceeded = useSelector(getAIAgentLimitExceeded)

  const inputPlaceholder = lng('ai:chat.inputPlaceholder')

  const { sendPrompt, isTyping, errors } = useData()

  const isPromptToLong = message.length >= MAX_VALUE_LENGTH

  const isSendButtonDisabled = isPromptToLong || isTyping

  const handleOnChangeInput = (e?: FormEvent<HTMLDivElement>) => {
    const val = e?.currentTarget?.innerText?.trim()
    setMessage(val)
  }

  const handleOnFocus = () => setIsFocused(true)

  const handleOnBlur = () => setIsFocused(false)

  const handleOnKeyDownInput = (e?: KeyboardEvent<HTMLDivElement>) => {
    if (e?.key === 'Enter') {
      e.preventDefault()
      if (!isSendButtonDisabled) {
        onClickSendPrompt()
      }
      return
    }
  }

  const onClickSendPrompt = async () => {
    inputRef.current.innerText = ''
    setMessage('')
    await sendPrompt({ message })
  }

  useEffect(() => {
    if (inputRef?.current) {
      if (isFocused) {
        if (!message || message === inputPlaceholder) {
          inputRef.current.innerText = ''
          setIsPlaceholder(false)
        }
      } else {
        if (
          !inputRef.current.innerText?.trim() ||
          message === inputPlaceholder
        ) {
          inputRef.current.innerText = inputPlaceholder
          setIsPlaceholder(true)
        }
      }
    }
  }, [isFocused, inputRef, message])

  return (
    <Wrapper>
      <Flex flexDirection="row" alignItems="center" gap="s">
        {isLimitExceeded ? (
          <Text>
            <Trans
              i18nKey="ai:chat.limitExceeded"
              components={{
                textLink: <ExternalTextLink href="/help/" target="_blank" />,
              }}
            />
          </Text>
        ) : (
          <>
            <Input
              ref={inputRef}
              isPlaceholder={isPlaceholder}
              contentEditable="plaintext-only"
              onInput={handleOnChangeInput}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDownInput}
            />
            <IconButtonStyled
              isDisabled={isSendButtonDisabled}
              name="20-paper-plane"
              onClick={onClickSendPrompt}
            />
          </>
        )}
      </Flex>
      {errors?.captchaToken ? (
        <Caption color="alert50">{errors.captchaToken}</Caption>
      ) : (
        <Caption color="greyDisabled">
          {lng('ai:chat.inputCaption')}
          {message?.length >= VALUE_LENGTH_SHOW_COUNTER && (
            <Counter isError={isPromptToLong}>
              {message.length} / {MAX_VALUE_LENGTH}
            </Counter>
          )}
        </Caption>
      )}
      <CloudflareTurnstile
        variant={TurnstileVariant.INVISIBLE}
        onSetToken={captchaToken => console.log(captchaToken)}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    border-top: ${p.theme.borders.thinGrey20};
    background-color: ${p.theme.colors.grey0};
    padding: ${p.theme.space.s}px;
    flex-flow: column;
    gap: ${p.theme.space.xxxs}px;
  `}
`

const Input = styled.div<{ isPlaceholder?: boolean }>`
  ${p => css`
    flex: 1;
    padding: ${p.theme.space.xs}px ${p.theme.space.s}px;
    border: ${p.theme.borders.thinGrey20};
    font-size: ${p.theme.fontSizes.m}px;
    background-color: ${p.theme.colors.inverted100};
    max-height: 164px;
    overflow-y: auto;

    ${p.isPlaceholder &&
    css`
      color: ${p.theme.colors.grey50};
    `}
    ${getScrollbarStyles()}
  `}
`

const Caption = styled(Text)`
  ${p => css`
    justify-content: space-between;
    display: flex;
    font-size: ${p.theme.fontSizes.s}px;
    padding-right: ${p.theme.space.xl + p.theme.space.s}px;
  `}
`

const Counter = styled(Text)<{ isError: boolean }>`
  ${p => css`
    font-size: ${p.theme.fontSizes.s}px;
    color: ${p.isError ? p.theme.colors.alert20 : p.theme.colors.greyDisabled};
  `}
`

const IconButtonStyled = styled(IconButton)`
  ${p => css`
    color: ${p.isDisabled
      ? p.theme.colors.greyDisabled
      : p.theme.colors.primary100};
  `}
`
